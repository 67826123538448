// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

//** HEADINGS **//
h1 { font-family: 'Montserrat', sans-serif; font-size:42px; line-height:50px; font-weight:600; color:#ffffff; }
h2 { font-family: 'Montserrat', sans-serif; font-size:36px; line-height:44px; font-weight:600; color:#343a40; }
h3 { font-family: 'Montserrat', sans-serif; font-size:23px; line-height:30px; font-weight:600; color:#343a40; margin-bottom:6px; }
h4 { font-family: 'Montserrat', sans-serif; font-size:21px; line-height:28px; font-weight:500; color:#8693a1; text-transform:uppercase; margin-top:-10px; }
.h5 { font-family: 'Montserrat', sans-serif; font-size:28px; line-height:36px; font-weight:600; }
.h6 { font-family: 'Montserrat', sans-serif; font-size:16px; line-height:23px; font-weight:400px; color:#a4b2c5; }
@media (max-width: 768px) { 
	.h5 { text-align:center !important; }
	.h6 { text-align:center !important; }
	}
p { font-family: 'Montserrat', sans-serif; font-size:16px; line-height:24px; font-weight:400; color:#666f7a; }
.highlight { font-family: 'Montserrat', sans-serif; font-size:16px; line-height:24px; font-weight:600; color:#1e51a4; }
.smaller { font-family: 'Montserrat', sans-serif; font-size:14px; line-height:21px; font-weight:400; color:#666f7a; margin-bottom:0px; }
.bigger { font-family: 'Montserrat', sans-serif; font-size:19px; line-height:26px; font-weight:400; color:#666f7a; }

//** GLOBAL **//
.active { color:#343a40 !important; }
.centerme { margin:0 auto; }
.icon { margin:0 auto; margin-left:6px; margin-right:6px; margin-bottom:2px; color:#1e51a4 !important; fill:#1e51a4 !important; }
.icon-sm { font-size:18px; height:18px !important; }
.icon-lrg { font-size:50px; height:50px !important; }
.lightgrey { color:#a4b2c5 !important; }
.whitebg { background:#ffffff; }
.greybg { background:#edf0f3; }
.footerbg { background:#343a40; }
.copyrightbg { background:#2a2e33; }
.anchor { position: relative; top: -85px; height: 1px; display: block; }
a { font-weight:inherit; color:#203469; }
a span { font-weight:600; }
@media (max-width: 768px) {
	.navbar-brand { width:50%; float:left; }
	.navbar-brand img { width:100%; float:none; }
	}

//** MENU **//
.navbar { -webkit-box-shadow:0 8px 6px -6px rgba(0,0,0,.2); -moz-box-shadow:0 8px 6px -6px rgba(0,0,0,.2); box-shadow:0 8px 6px -6px rgba(0,0,0,.2); }
.navbar-light .navbar-nav .nav-link { font-family: Montserrat; font-weight:600; color:#8693a1; }
.navbar-expand-lg .navbar-nav .nav-link { padding-left: 1rem; padding-right:1rem; }
.nav-link { font-size:14px; text-transform:uppercase; }
.contact-link { font-family: 'Montserrat', sans-serif; font-weight:600; font-size:12px; text-transform:uppercase; height:18px; vertical-align:middle; color:#8693a1 !important; }
div.social a { margin-right:3px; }

//** BUTTONS **//
.btn { display:block; padding: 10px 16px 10px 16px; }
.btn-primary { display:block; font-family: Montserrat; font-size:14px; font-weight:800; text-transform:uppercase; color:#fff !important; background-color:#1e51a4; border-color:#1e51a4; }
.btn-primary:hover { background-color:#1d458c; border-color:#1d458c; }
.btn-primary:active { background-color:#1e51a4; border-color:#1e51a4; }
.btn-secondary { display:block; font-family: Montserrat; font-size:14px; font-weight:800; text-transform:uppercase; background-color:#f35609; border-color:#f35609; }
.btn-secondary:hover { background-color:#eb5308; border-color:#eb5308; }
.btn-secondary:active { background-color:#f35609; border-color:#f35609; }

/** HERO **/
.varbg-1 { background-color:#edf0f3; }
.home-hero { padding: 120px 0; vheight:400px; background-image:url("../img/home-hero-background.jpg"); background-repeat:no-repeat; background-size: cover; background-position:center; }
.contact-hero { padding: 75px 0; vheight:275px; background-image:url("../img/contact-us-hero-background.jpg"); background-repeat:no-repeat; background-size: cover; background-position:center; }
.contact-hero p { margin-bottom:0px !important; }

//** CTA **//
.requestdemo { background-color:#23272b; background-image:url("../img/request-a-demo-background_v2.jpg"); background-repeat:no-repeat; background-size: cover; background-position:center; }
.testimonials { background-color:#edf0f3; background-image:url("../img/testimonials-background.jpg"); background-repeat:no-repeat; background-size:cover; background-position:center; }
.brochure { background-color:#203469; background-image:url("../img/product-overview-background_v2.jpg"); background-repeat:no-repeat; background-size:cover; background-position:center; }
.government { background-color:#203469; background-image:url("../img/government-background_v2.jpg"); background-repeat:no-repeat; background-size:cover; background-position:center; }

//** CAROUSEL **//
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev{
    display:block;
}